import {
  ExpiredStatuses,
  RegistrationQuery,
} from '@/components/consultation/consultation.typedefs';
import { getConsultationRedirectLink } from '@/controllers/router/router.utils/learn';
import { PromoCodeLandingFragment } from '@/controllers/promoCode/generated/promoCodeLanding.fragment.generated';
import { DAY } from '@/constants';
import { MinimumArray } from '@/lib/helpers/utility-types';
import { checkArrayLength } from '@/lib/helpers/checkArrayLength';

export const getNextExpirationTime = (
  startedAtTime: number,
  repeatDaysSteps: MinimumArray<1, number>,
) => {
  const currentTime = Date.now();

  // Calculate the total elapsed time in days since the start
  const elapsedTimeInDays = Math.floor((currentTime - startedAtTime) / DAY);

  // Sum the intervals and determine the next interval based on the start time
  let totalDays = 0;
  let intervalIndex = 0;

  while (totalDays <= elapsedTimeInDays) {
    const currentInterval = (
      repeatDaysSteps[intervalIndex]
      || repeatDaysSteps[0]
    );

    totalDays += currentInterval;

    intervalIndex = (intervalIndex + 1) % repeatDaysSteps.length;
  }

  // Calculate the next expiration time based on the startedAtTime
  return startedAtTime + totalDays * DAY;
};

export const successPageData = ({
  courseSlug,
  shouldRedirectToTrialIntro,
  shouldRedirectToIntroductionTopic,
  moduleSlug,
  topicSlug,
}: {
  courseSlug?: string;
  shouldRedirectToTrialIntro?: boolean;
  shouldRedirectToIntroductionTopic?: boolean;
  moduleSlug?: string;
  topicSlug?: string;
}) => ({
  redirectLink: getConsultationRedirectLink({
    courseSlug,
    shouldRedirectToTrialIntro,
    shouldRedirectToIntroductionTopic,
    moduleSlug,
    topicSlug,
  }),
  text: 'success_page_pt_text',
  buttonName: 'success_page_pt_submit',
});

export const buildQuery = (query: RegistrationQuery) => {
  const queryString = Object.entries(query)
    .map(([key, value]) => (
      `${key}=${value}`
    ))
    .join('&');

  return queryString.length > 0
    ? `?${queryString}`
    : '';
};

interface Options {
  promoCode: PromoCodeLandingFragment;
  promoFirstInteractionTime: number;
}

export const getTimerExpiredAtTime = ({
  promoCode,
  promoFirstInteractionTime,
}: Options): number => {
  const {
    status,
    startedAt,
    expiredAt,
    timerConfig,
  } = promoCode;

  if (ExpiredStatuses.includes(status)
    || (expiredAt && Number(expiredAt) <= Date.now())
  ) {
    return 0;
  }

  if (expiredAt && !timerConfig?.repeatDaysSteps) {
    return Number(expiredAt);
  }

  const startedAtTime = timerConfig?.useFirstInteractionTime
    ? promoFirstInteractionTime
    : Number(startedAt);

  const repeatDaysStepsFromTimerConfig: number[] = timerConfig?.repeatDaysSteps
    ? timerConfig.repeatDaysSteps
    : [];

  const repeatDaysSteps: MinimumArray<1, number> = (
    checkArrayLength(repeatDaysStepsFromTimerConfig, 1)
      ? repeatDaysStepsFromTimerConfig
      : [timerConfig?.repeatDaysStep || 3]
  );

  const nextExpirationTime = getNextExpirationTime(
    startedAtTime,
    repeatDaysSteps,
  );

  if (expiredAt && nextExpirationTime > Number(expiredAt)) {
    return Number(expiredAt);
  }

  return nextExpirationTime;
};
