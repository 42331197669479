import { PromoCodeStatus } from '@/controllers/graphql/generated';
import { useSubDomainContext } from '@/controllers/subDomain/subDomain.hooks/useSubDomainContext';
import { SubDomains } from '@/constants/subdomains';
import { PromoCodeLandingBaseFragment } from '@/controllers/promoCode/generated/promoCodeLandingBase.fragment.generated';

interface Options {
  courseSlug?: string;
  promoCode?: PromoCodeLandingBaseFragment;
}

export const useShouldShowFlyerOnePromo = (
  options: Options,
) => {
  const { promoCode, courseSlug } = options;
  const { subDomain } = useSubDomainContext();

  const isCorrectDomain = subDomain === SubDomains.ua;
  const isPromoCodeActive = promoCode?.status === PromoCodeStatus.Active;

  const validCourses = ['frontend', 'fullstack'];
  const isCorrectCourse = Boolean(
    courseSlug
    && validCourses.includes(courseSlug),
  );

  return (
    isCorrectDomain
    && isPromoCodeActive
    && isCorrectCourse
  );
};
