import { MinimumArray } from '@/lib/helpers/utility-types';

export const checkArrayLength = (
  <
    A extends any,
    L extends number,
  >(
    array: A[],
    minimumLength: L,
  ): array is MinimumArray<L, A> => array.length >= minimumLength
);
